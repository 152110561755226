var J = jQuery.noConflict();

/* Main Functions On document ready */
J(document).ready(function() {
  /* Animate to */
  function animateTo(elem) {
    J('html, body').stop().animate({
      'scrollTop': J(elem).offset().top
    }, 400, 'swing');
  }

  J('down').click(function() {
    animateTo('.content');
  });

  // call Sitcky
  sticky('header');

  // Menu accordeon
  var mediaquery = window.matchMedia("(max-width: 768px)");

  J('.drop').click(function(event) {
    if (mediaquery.matches) {
      J(this).siblings('.submenu').slideToggle();
    }
  });

  J("#header .menu li").mouseenter(function() {
    var Main = J(this);
    var Wrapper = Main.find('.submenu');

    if (!mediaquery.matches) {
      Wrapper.slideDown();
    }
  });

  J("#header .submenu").mouseleave(function() {
    var Main = J(this);

    if (!mediaquery.matches) {
      Main.slideUp();
    }
  });


  // Filters accordeon
  J("#sitemap-footer .help").click(function() {
    var Main = J(this);
    var Wrapper = J('#sitemap-footer .submenu');

    if (Main.hasClass('open')) {
      Main.removeClass('open');
      Wrapper.slideUp();
    }else{
      Main.addClass('open');
      Wrapper.slideDown();
    }
  });

  // Store Filters accordeon
  J(".filters .more").click(function() {
    var Main = J(this);
    var Wrapper = J('.filters form');

    if (Main.hasClass('open')) {
      Main.removeClass('open');
      Wrapper.slideUp();
    }else{
      Main.addClass('open');
      Wrapper.slideDown();
    }
  });

  // size gauge on product
  J("#guide").click(function() {
    J('#sizes-overlay').addClass('fadeInDownBig');
  });

  J(".close-sizes").click(function() {
    J('#sizes-overlay').removeClass('fadeInDownBig');
  });

  // Selectric Init
  J(function() {
    J('select.electric').selectric({
      arrowButtonMarkup: '<b class="button-drop"></b>',
      disableOnMobile: false,
    });
  });

  // Star rating init
  J('.ratings').barrating({
    theme: 'bars-square'
  });

  J(function() {
    J('select.electric').selectric({
      arrowButtonMarkup: '<b class="button-drop"></b>',
      disableOnMobile: false,
    });
  });

  // Chosem init
  J(".chosen-select").chosen({
    width: "100%"
  });

  // Responsive Tables
  J('table.stack').cardtable();

  // Cloud zoom init
  var $easyzoom = J('.easyzoom').easyZoom();
  var api = $easyzoom.filter('.easyzoom').data('easyZoom');

  J('.thumbnails').on('click', 'a', function(e) {
    var $this = J(this);
    e.preventDefault();
    api.swap($this.data('standard'), $this.attr('href'));
  });

  // Varieties on Product
  J('.color').on('click', function(){
    var item = J(this);
    var v = item.attr('data-id');

    if(!item.hasClass('active')){
      J('.color').removeClass('active');
      item.addClass('active');
    }

    J.ajax({
      url: '/shop/product/variety',
      type: 'POST',
      dataType: 'json',
      data: {
        id: v
      },
      success: function(data) {
        J('.sizes').hide();
        J('#size-'+v).show();
        var thumbnails = '';
        var gallery = '';
        for (var i = 0; i < data.length ; i++) {
          thumbnails += ''
          thumbnails += '<li>';
          thumbnails += '<a href="/'+ data[i].src + data[i].img +'" data-standard="/'+ data[i].src + data[i].img +'">';
          thumbnails += '<img src="/'+ data[i].src + data[i].img +'" alt="">';
          thumbnails += '</a>';
          thumbnails += '</li>';
        }
        J('.thumbnails').html(thumbnails);
      }
    });
  });

  J('.v-sizes').on('click', function(){
    var item = J(this);
    var v = J(this).attr('data-id');
    var price = J(this).attr('data-price');
    var promo = J(this).attr('data-promo');

    if(!item.hasClass('active')){
      J('.v-sizes').removeClass('active');
      item.addClass('active');
    }

    J('#variety').val(v);
    J('#price').html(price);
    J('#promo span').html(promo);
  });

  // Whislist functionality
  J('.wishlist-add').on('click', function(){
    J.ajax({
      url: '/shop/wishlist/add',
      type: 'POST',
      dataType: 'json',
      data: {
        id: J('#id').val(),
        variety: J('#variety').val()
      },
      success: function(data) {
        if (data.status == 'ok') {
          J('.notify-title').html(data.lang);
          J('.notify-wrapper').show();
        }else{
          window.location.href = data.route;
        }
      }
    });
  });


});

/* Functions at resize */
J(window).resize(function() {
});

/* Functions */

/* StickyNav function */
function sticky(elem) {
  var menu = J(elem);
  var breakpoint = menu.outerHeight();

  if (menu.length) {
    //attaching a scroll event
    J(window).scroll(function () {
      if (J(window).scrollTop() > breakpoint) {
        menu.addClass('sticky fadeInDown');
        J('body').css('padding-top',breakpoint);
      } else {
        menu.removeClass('sticky fadeInDown');
        console.log("else");
        J('body').css('padding','0 0 0 0');
      }
    });
  };
}

function checkSubmit() {
    document.getElementById("btn-payment").value = "Enviando...";
    document.getElementById("btn-payment").disabled = true;
    return true;
}
