var utils = (function(window, $) {
  var self = this;

  self.setMenuColor = function(menuref) {
    $('a[data-page="'+menuref+'"]').addClass('active');
  };

  self.setAjaxToken = function() {
    $.ajaxSetup({
      headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
    });
  };

  self.getJsonformData = function(form) {
    var formArray = $(form).serializeArray(),
        indexed_data = {};

    $.map(formArray, function(n, i) {
      indexed_data[n['name']] = n['value'];
    });

    return indexed_data;
  };

  self.isDataEmpty = function(obj) {
    for(var prop in obj) {
      if(obj[prop] == "")
        return true;
    }

    return false;
  };

  self.validateIfNumbers = function(e) {
    if (e.which!==8 && e.which!==0 && (e.which<45 || e.which>57)) {
      return false;
    }
  };

  return self;
})(window, jQuery);
