J(document).ready(function() {

  J('.scs').on('change', function(e) {
    e.preventDefault();
    var city = J(this).val();

    console.log(city);

    J.ajax({
      url: '/shop/update/shipping-city',
      type: 'POST',
      dataType: 'json',
      data: {
        city: city
      },
      success: function(data) {
        if (data.success) {
          location.reload();
        }
      }
    });
  });

  J('.scountry').on('change', function(e) {
    e.preventDefault();
    var country = J(this).val();

    console.log(country);

    J.ajax({
      url: '/shop/update/shipping-country',
      type: 'POST',
      dataType: 'json',
      data: {
        country: country
      },
      success: function(data) {
        if (data.success) {
          location.reload();
        }
      }
    });
  });

  J('#qty').submit(function(e) {
    e.preventDefault();
    if (J('#quantity').val()) {
      J.ajax({
        url: '/shop/addcart',
        type: 'POST',
        dataType: 'json',
        data: {
          id: J('#product_id').val(),
          qty: J('#quantity').val()
        },
        error: function() {
           alert('error')
        },
        success: function(data) {
          if (data.success) {
            window.location.href = data.redirect;
          }else{
            alert(data.message)
          }
        }
      });
    }else{
      alert('No cero')
    }
  });

  J('#updatecart, .updatecart').click(function(e) {
    e.preventDefault();
    var products = J('input[name="products[]"]').map(function(){
      return this.value;
    }).get();
    var quantity = J('input[name="quantity[]"]').map(function(){
      if (this.value != this.defaultValue) {
        return this.value;
      }
      return 'not';
    }).get();
    var rowid = J('input[name="rowid[]"]').map(function(){
      return this.value;
    }).get();

    var locale = J('input[name="locale"]').val();

    J.ajax({
      url: '/shop/updatecart',
      type: 'POST',
      dataType: 'json',
      data: {
        products: products,
        quantity: quantity,
        rowid: rowid,
        locale: locale
      },
      success: function(data) {
        if (data.success) {
          window.location.href = data.redirect;
        }else{
          var html = '<div class="alert alert-warning  alert-dismissible">'
          html += '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
          for (var i = 0; i < data.nostock.length; i++) {
            html += '<p>'+ data.nostock[i] +':  '+ data.message +'</p>';
          }
          html += '</div>';
          J('.nostock').html(html);
          J.map(data.cart, function(row, i){
            J('input#qty-product-'+row.id).val(row.qty);
          });
        }
      }
    });
  });

  J('.myQty').on('change', function(e) {
    e.preventDefault();

    var selected = J(this).find("option:selected").val();

    if(selected == 'more'){
      J(this).hide();
      J(this).siblings('.refresh-cart').show();
      J(this).siblings('.refresh-cart').show();
    }else{
      var quantity = J(this).val();
      var product = J(this).siblings('input[name="myProduct"]').val();
      var rowid = J(this).siblings('input[name="myID"]').val();

      var locale = J('input[name="locale"]').val();

      //alert('Quantity=' + quantity + ' Product=' + product + ' RowId=' + rowid)

      J.ajax({
        url: '/shop/updateselectcart',
        type: 'POST',
        dataType: 'json',
        data: {
          product: product,
          quantity: quantity,
          rowid: rowid,
          locale: locale
        },
        success: function(data) {
          if (data.success) {
            window.location.href = data.redirect;
          }else{
            var html = '<div class="alert alert-warning  alert-dismissible">'
            html += '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
            for (var i = 0; i < data.nostock.length; i++) {
              html += '<p>'+ data.nostock[i] +':  '+ data.message +'</p>';
            }
            html += '</div>';
            J('.nostock').html(html);

            J.map(data.cart, function(row, i){
              J('.qty-product-'+row.id).val(row.qty);
            });
          }
        }
      });
    }
  });

});
